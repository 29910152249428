<style type="text/css">
.he-tree {
    border: 1px solid #ccc;
    padding: 20px;
}

.tree-node {}

.tree-node-inner {
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    height: 42px;
}

.draggable-placeholder {}

.draggable-placeholder-inner {
    border: 1px dashed #0088F8;
    box-sizing: border-box;
    background: rgba(0, 136, 249, 0.09);
    color: #0088f9;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
}

b {
    padding: 7px;
}
</style>
<template>
    <section class="content">
        <div class="row">
            <div class="col-12">
                <div class="mb-2">
                    <button class="btn btn-primary" @click="collapseAll">Collapse All</button>
                    <button class="btn btn-primary ml-1" @click="expandAll">Expand All</button>
                    <button class="btn btn-primary ml-1" @click="addParent">Add Parent</button>
                </div>
                <Tree :data="tree1data" draggable="draggable" cross-tree="cross-tree" @drag="ondrag" @drop="ondrop">
                    <div slot-scope="{data, store}">
                        <template v-if="!data.isDragPlaceHolder">
                            <b v-if="data.children &amp;&amp; data.children.length" @click="store.toggleOpen(data)">{{ data.open ? '-' : '+' }}&nbsp;</b><span>{{ data.text }}</span>
                            <div class="btn-group" style="float: right;">
                                <button type="button" class="btn btn-default" @click="add(data.data)"><i class="fas fa-plus"></i></button>
                                <button type="button" class="btn btn-default" @click="edit(data.data)"><i class="fas fa-pen"></i></button>
                                <button type="button" class="btn btn-default" @click="remove(data, store)"><i class="fas fa-trash-alt"></i></button>
                            </div>
                        </template>
                    </div>
                </Tree>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <input type="hidden" name="menu_id" value="" v-model="form.menu_id" />
                                    <label class="control-label">Parent</label>
                                    <v-select :options="parenopt" :reduce="opt => opt.code" :value="form.menu_parent" v-model="form.menu_parent"></v-select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Name</label>
                                    <input class='form-control' v-model="form.menu_name" type='text' required='required' name='menu_name' />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Menu URL</label>
                                    <input type="text" name="menu_url" placeholder="" v-model="form.menu_url" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Ajax Url</label>
                                    <input type="checkbox" name="menu_ajax" placeholder="" v-model="form.menu_ajax" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Enable</label>
                                    <input type="checkbox" name="menu_active" placeholder="" v-model="form.menu_active" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Module</label>
                                    <v-select :options="moduleopt" :reduce="opt => opt.code" :value="form.module_id" v-model="form.module_id"></v-select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Category</label>
                                    <input type="text" name="menu_category" placeholder="" v-model="form.menu_category" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Sort</label>
                                    <input type="text" name="menu_weight" placeholder="" v-model="form.menu_weight" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Icon</label>
                                    <input type="text" name="menu_icon" placeholder="" v-model="form.menu_icon" class="form-control" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Description</label>
                                    <input type="text" name="menu_description" placeholder="" v-model="form.menu_description" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script type="text/javascript">
import { createTable, authFetch } from '@/libs/hxcore';
import { DraggableTree } from 'vue-draggable-nested-tree';
import { depthFirstSearch, breadthFirstSearch } from 'tree-helper';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import $ from 'jquery';

export default {
    name: 'Menu',
    components: { Tree: DraggableTree, vSelect },
    data() {
        return {
            errors: [],
            method: '',
            tree1data: [
                { text: 'node 1', data: { id: 20 } },
            ],
            formTitle: 'Add Menu',
            form: {
                menu_parent: '',
                menu_name: '',
                menu_url: '',
                menu_ajax: '',
                menu_active: '',
                module_id: '',
                menu_category: '',
                menu_weight: '',
                menu_icon: '',
                menu_description: '',
            },
            parenopt: [
                { label: 'Read', code: 'read' },
                { label: 'create', code: 'create' },
                { label: 'update', code: 'update' },
                { label: 'delete', code: 'delete' }
            ],
            moduleopt: [
                { label: 'Read', code: 'read' },
                { label: 'create', code: 'create' },
                { label: 'update', code: 'update' },
                { label: 'delete', code: 'delete' }
            ],
        }
    },
    methods: {
        // add child to tree2
        addChild() {
            this.tree2data[0].children.push({ text: 'child' })
        },
        expandAll() {
            breadthFirstSearch(this.tree1data, node => {
                node.open = true
            })
        },
        collapseAll() {
            breadthFirstSearch(this.tree1data, node => {
                node.open = false
            })
        },
        ondrag() {
            const tree = this.$refs.tree1
            // tree.nodesTransition = 'fade'
        },
        ondrop(node, target, old) {
            const tree = this.$refs.tree1
            console.log(node.parent.text);
            authFetch('/users/menu/drag/' + node.data.menu_id + '/' + node.parent.data.menu_id, {
                    method: 'PUT',
                })
                .then(res => {
                    console.log(res)
                })
                .then(js => {

                });
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/users/menu';
            if (this.method == 'PUT') urlSubmit = '/users/menu/' + this.form.menu_id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    } else {
                        $(e.formDialog).modal('hide');
                        this.reload()
                    }
                });

            ev.preventDefault();
        },
        addParent: function(e) {
            const a = this.$refs;
            this.form = {};
            this.method = 'POST';
            this.errors = [];
            this.formTitle = 'Tambah Menu';
            $(a.formDialog).modal('show');
        },
        add: function(e) {
            const a = this.$refs;
            this.form = {};
            this.method = 'POST';
            this.errors = [];
            this.formTitle = 'Tambah Menu';
            this.form.menu_parent = e.menu_id
            $(a.formDialog).modal('show');
        },
        edit: function(e) {
            const a = this.$refs;
            this.form = {};
            this.method = 'PUT';
            this.errors = [];
            this.formTitle = 'Edit Menu';
            this.form = e;
            $(a.formDialog).modal('show');
        },
        remove: function(data, store) {
            authFetch('/users/menu/' + data.data.menu_id, {
                    method: 'DELETE'
                })
                .then(res => {
                    return res.json();
                })
                .then(js => {
                    if (js.success) {
                        store.deleteNode(data);
                    }
                });
        },
        reload: function(e) {
            var _this = this
            authFetch('/users/menu/', {
                    method: 'GET',
                })
                .then(res => {
                    return res.json();
                })
                .then(js => {
                    this.tree1data = [];
                    Object.keys(js).map(function(key, index) {
                        _this.tree1data.push(js[key]);
                        Object.keys(js[key]).map(function(k, i) {
                            if (js[key][k].children) {
                                _this.tree1data[index].children.push(js[key][k].children);
                            }
                        })
                    });
                    $('.loading-overlay').removeClass('show');
                });

            authFetch('/users/menu/parent_menu', {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(js => {
                    this.parenopt = js.success;
                });

            authFetch('/users/menu/module_opt', {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(js => {
                    this.moduleopt = js.success;
                });
        }
    },
    mounted() {
        this.reload()
    }
}
</script>